import React from "react";
import { Route, Routes as Switch } from "react-router-dom";
import Arajanlatozo from "../pages/arajanlatozo";
import ASZF from "../pages/Aszf";
import Bejelentkezes from "../pages/Bejelentkezes";
import Blog from "../pages/Blog";
import NotFound from "../pages/NotFound";
import Solar from "../pages/Solar";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" element={<Solar />} />
      <Route path="/vallalati" element={<Solar />} />
      <Route path="/lakossagi" element={<Solar />} />
      <Route path="/aszf" element={<ASZF />} />
      <Route path="/blog/:id" element={<Blog />} />
      <Route path="/bejelentkezes" element={<Bejelentkezes />} />
      <Route
        path="/f9a3c2dd20d43eda5d3e522ba2b79dfc"
        element={<Arajanlatozo />}
      />

      <Route path="*" element={<NotFound />} />
    </Switch>
  );
};

export default Routes;
