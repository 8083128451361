import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuContext } from "../../contexts/menu-context";
import "./footer.scss";

const Footer = () => {
  const { isDark } = useContext(MenuContext);
  const [isScrolled, setIsScrolled] = useState(false);
  const [createdText, setCreatedText] = useState("Készítette: FilyoDev");

  useEffect(() => {
    const scrollHandler = () => {
      if (
        document.documentElement.scrollTop >
        document.body.scrollHeight - 1400
      ) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  });

  const location = useLocation();

  return location.pathname === "/f9a3c2dd20d43eda5d3e522ba2b79dfc" ? (
    <></>
  ) : (
    <div
      className={`footer ${isScrolled ? "active" : ""}`}
      style={isDark ? { background: "#000", color: "#fff" } : {}}>
      <ul>
        {/* &copy; 2022 */}
        <li>Green Island Invest Zrt. </li>
        <Link to="/aszf">
          <li>Adatvédelmi tájékoztató</li>
        </Link>
        <li>
          <a
            href="mailto:filyodev@gmail.com"
            onMouseEnter={() => setCreatedText("filyodev@gmail.com")}
            onMouseLeave={() => setCreatedText("Készítette: FilyoDev")}>
            {createdText}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
