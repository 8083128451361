import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import FloatingChatButton from "../floatingchat/FloatingChat";

import "../solar/solar-model.scss";

const BlogModel = () => {
  const chatButton = useRef();
  const [blogData, setBlogData] = useState({});
  const { id } = useParams();

  useEffect(() => {
    fetch("https://greenisland-api.onrender.com/blog/" + id)
      .then((res) => res.json())
      .then((data) => {
        setBlogData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [id]);

  return (
    <>
      <div className="solar">
        <div className="solar__hero fade-bottom">
          <img
            src={require(`../../assets/solar/oktatoshatter.jpg`)}
            className="full-imagee"
            alt="hero"></img>
          <div
            className="solar__hero__info"
            style={{ color: "#fff", zIndex: 1, marginTop: "50dvh" }}>
            <motion.h1
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}>
              {blogData.title}
            </motion.h1>
          </div>
        </div>

        <div
          className="solar__section__specs"
          style={{ marginBottom: "150px" }}>
          <div
            className="solar__section__specs__div"
            style={{ flexWrap: "wrap" }}>
            <div className="solar__section__specs__div__main">
              <pre className="blog-text">{blogData.content}</pre>
            </div>
            <div className="solar__section__specs__div__main">
              <div style={{ marginTop: "48px" }}>
                {blogData.file ? (
                  blogData.file.includes(".jpeg") ||
                  blogData.file.includes(".jpg") ||
                  blogData.file.includes(".png") ||
                  blogData.file.includes(".gif") ? (
                    <img
                      className="blog-video"
                      src={
                        "https://greenisland-api.onrender.com" + blogData.file
                      }
                      alt="blog"></img>
                  ) : (
                    <video
                      className="blog-video"
                      src={
                        "https://greenisland-api.onrender.com" + blogData.file
                      }
                      controls></video>
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="solar__footer" id="kapcsolat">
          <div className="solar__footer__container">
            <h2>Green Island Invest Zrt.</h2>
            <div style={{ fontSize: "0.8rem" }}>
              <p>2074 Perbál Ipari Park 17</p>
              <p>info@greenislandinvest.hu</p>
              <p>+36 1 300 9240</p>
              <p>+36 70 / 315-7070</p>
              <p>+36 70 / 309-7070</p>
            </div>
            <div className="solar__footer__container__btns">
              <button
                className="btn-secondary-inverse"
                onClick={() => chatButton.current.openChat()}>
                Visszahívást kérek
              </button>
            </div>
          </div>
        </div>
      </div>
      <FloatingChatButton ref={chatButton} />
    </>
  );
};

export default BlogModel;
