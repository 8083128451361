export const getHomeData = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/home.json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Error when fetching home data!");
    }

    const data = await response.json();

    let products = [];
    for (const key in data) {
      products.push({
        id: key,
        name: data[key].name,
        description: data[key].description,
        img_desktop: data[key].img_desktop,
        img_mobile: data[key].img_mobile,
      });
    }

    return products;
  } catch (error) {
    console.error(error);
  }
};

export const getCarModel = async (id) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/items/${id}.json`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Error when fetching car model data!");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getSolarModel = async (id) => {
  try {
    const response = {
      "about_img_desktop": "panels-about-desktop.jpg",
      "about_img_mobile": "panels-about-mobile.jpg",
      "about_info": "Our solar panels are low-profile and durable — quietly converting sunlight to energy for decades to come. Integrated hardware and simple design achieve this by securing the panels close to your roof and to each other for a minimalist aesthetic. Chat with an energy advisor to ask any questions about Tesla solar panels.",
      "about_title": "Sleek and Durable",
      "about_type": "Design",
      "certification": "IEC / UL 61730, CEC Listed",
      "control_img": "panels-control.jpg",
      "control_info": "Manage your solar system from anywhere in the world with 24/7 mobile monitoring. Watch your energy in real time or set your preferences to optimize for energy independence.",
      "control_title": "24 / 7 Monitoring",
      "control_type": "Experience",
      "description": "A jövő energiája",
      "design": "Black sollar cells and aluminium backsheet",
      "dimensions": "74.4\" x 41.2\" x 1.57\"",
      "fire": "Class A (highest rating)",
      "footer_title": "Power Your Home",
      "heading": "Napelemek az otthonodra",
      "hero_img_desktop": "panels-hero-desktop.jpg",
      "hero_img_mobile": "panels-hero-mobile.jpg",
      "isDark": false,
      "name": "Solar Panel",
      "power": "3.8kW / 7.6kW 97.5% efficiency",
      "protection_info": "Use solar energy to power your home and reduce your dependence on the grid. Purchase solar at the lowest price of any national provider with Tesla's price match guarantee and take control of your monthly electricity bill. Learn more about your potential savings in our Design Studio.",
      "protection_title": "Electricity For Less",
      "protection_type": "Savings",
      "protection_video": "panels-protection.mp4",
      "specs_img": "panels-specs.jpg",
      "temperature": "-40°F up to +185°F",
      "warranty": "25-year performance guarantee",
      "wattage": "400 W"
  };

    return response;
  } catch (error) {
    console.error(error);
  }
};
