import {
  ArrowBackRounded,
  AttachMoneyRounded,
  RefreshRounded,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { TextareaAutosize } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import $ from "jquery";
import React, { useEffect } from "react";
import "../arajanlatozo/arajanlatozo.scss";
import "../solar/solar-model.scss";
import json from "./arak.json";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ArajanlatozoModel = () => {
  const [nettoAr, setNettoAr] = React.useState(0);
  const [afaAr, setAfaAr] = React.useState(0);
  const [teljesAr, setTeljesAr] = React.useState(0);
  const [napelem, setNapelem] = React.useState("");
  const [inverter, setInverter] = React.useState("");
  const [akkumulator, setAkkumulator] = React.useState("");
  const [klima, setKlima] = React.useState("");
  const [kiegeszito, setKiegeszito] = React.useState("");
  const [frissites, setFrissites] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const [lastChangedByAdmin, setLastChangedByAdmin] = React.useState(false);
  const [lastValueByAdmin, setLastValueByAdmin] = React.useState(0);
  const [isBlog, setIsBlog] = React.useState(false);
  const [blogcim, setBlogcim] = React.useState("");
  const [blogtartalma, setBlogtartalma] = React.useState("");
  const [blogs, setBlogs] = React.useState([]);
  const [currentEdit, setCurrentEdit] = React.useState(null);

  useEffect(() => {
    fetch("https://greenisland-api.onrender.com/blog")
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleChangeNapelem = (event) => {
    setNapelem(event.target.value);
  };
  const handleChangeAkkumulator = (event) => {
    setAkkumulator(event.target.value);
  };
  const handleChangeKlima = (event) => {
    setKlima(event.target.value);
  };
  const handleChangeInverter = (event) => {
    setInverter(event.target.value);
  };
  const handleChangeKiegeszito = (event) => {
    setKiegeszito(event.target.value);
  };

  let ugyfelnev = "";
  let ugyfelcim = "";
  let ertekesitonev =
    sessionStorage.getItem("felhasznalonev") === "Greenisland4611"
      ? ""
      : sessionStorage.getItem("nev");
  let ertekesitotelefon =
    sessionStorage.getItem("felhasznalonev") === "Greenisland4611"
      ? ""
      : sessionStorage.getItem("telefonszam");
  let napelemdb = 0;
  let klimadb = 0;
  let inverterdb = 0;
  let akkumulatordb = 0;
  let kiegeszitokdb = 0;

  let adminElements;
  let munkadij = 0;
  let tartoszerkezet = 0;
  let egyeb = 0;

  for (const key in json) {
    if (key === "telepites") {
      for (const [, d] of Object.entries(json[key])) {
        if (d["nev"] === "tartószerkezet") {
          tartoszerkezet = d["ar"];
        } else if (d["nev"] === "munkadíj") {
          munkadij = d["ar"];
        } else {
          egyeb += d["ar"];
        }
      }
    }
  }

  let teljes = 0;
  let afa = 0;
  let teljesafaval = 0;

  let isAdmin = false;
  if (!sessionStorage.getItem("felhasznalonev")) {
    window.location.href = "bejelentkezes";
  }

  isAdmin = sessionStorage.getItem("felhasznalonev") === "Greenisland4611";
  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#000",
      },
    },
  });

  let loadingButton;

  if (isLoading) {
    loadingButton = (
      <div className="load-wrapp">
        <div className="load-2">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    );
  } else {
    if (isError) {
      loadingButton = <span>Hiba történt!</span>;
    } else if (isSuccess) {
      loadingButton = <span>Sikeresen árajánlat!</span>;
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } else {
      loadingButton = <span>Árajánlat készítése</span>;
    }
  }

  const handleBlur = (e) => {
    setLastChangedByAdmin(true);
    setLastValueByAdmin(parseInt(e.target.textContent.replace(".", "")));

    getArak(parseInt(e.target.textContent.replace(".", "")));
  };

  const handleBlogFrissites = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const requestOptions = {
      method: currentEdit.id ? "PUT" : "POST",
      body: data,
    };

    let url =
      "https://greenisland-api.onrender.com/blog" +
      (currentEdit.id ? `/${currentEdit.id}` : "");
    fetch(url, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
        setCurrentEdit(null);
        loadData();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        alert("hiba", error);
        console.error("There was an error!", error);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    napelemdb = data.get("napelemekszama");
    klimadb = data.get("klimakszama");
    inverterdb = data.get("inverterekszama");
    akkumulatordb = data.get("akkumulatorokszama");
    kiegeszitokdb = data.get("kiegeszitokszama");
    if (frissites) {
      getArak(-1);
      setLastChangedByAdmin(false);
    } else {
      setLoading(true);
      let elemek = [];
      if (
        parseInt(napelemdb) !== 0 &&
        napelem &&
        JSON.parse(napelem)["nev"] !== 0 &&
        JSON.parse(napelem)["nev"] !== ""
      ) {
        elemek.push({
          name: JSON.parse(napelem)["nev"],
          quantity: napelemdb + " db",
        });
        elemek.push({
          name: "Tartószerkezet napelempanelek telepítéshez szereléssel",
          quantity: napelemdb + " db",
        });
      }
      if (
        parseInt(inverterdb) !== 0 &&
        inverter &&
        JSON.parse(inverter)["nev"] !== 0 &&
        JSON.parse(inverter)["nev"] !== ""
      ) {
        elemek.push({
          name: JSON.parse(inverter)["nev"],
          quantity: inverterdb + " db",
        });
      }

      if (
        parseInt(akkumulatordb) !== 0 &&
        akkumulator &&
        JSON.parse(akkumulator)["nev"] !== 0 &&
        JSON.parse(akkumulator)["nev"] !== ""
      ) {
        elemek.push({
          name: JSON.parse(akkumulator)["nev"],
          quantity: akkumulatordb + " db",
        });
      }

      if (
        parseInt(klimadb) !== 0 &&
        klima &&
        JSON.parse(klima)["nev"] !== 0 &&
        JSON.parse(klima)["nev"] !== ""
      ) {
        elemek.push({
          name: JSON.parse(klima)["nev"],
          quantity: klimadb + " db",
        });
      }

      if (
        parseInt(kiegeszitokdb) !== 0 &&
        kiegeszito &&
        JSON.parse(kiegeszito)["nev"] !== 0 &&
        JSON.parse(kiegeszito)["nev"] !== ""
      ) {
        elemek.push({
          name: JSON.parse(kiegeszito)["nev"],
          quantity: kiegeszitokdb + " db",
        });
      }

      let arak;

      if (lastChangedByAdmin) {
        arak = getArak(lastValueByAdmin);
      } else {
        arak = getArak(-1);
      }

      let today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      today = yyyy + "." + mm + "." + dd;

      let kw = napelem.split(" ")[napelem.split(" ").length - 1];

      elemek.push({
        name: "Szerelési anyagok, szolár kábelek és csatlakozók",
        quantity: "1 klt",
      });

      elemek.push({
        name: "AC-DC biztonsági szerelvények, túlfeszültségvédelem",
        quantity: "1 klt",
      });

      elemek.push({
        name: "Tervezés, engedélyezés, érintésvédelem",
        quantity: "1 klt",
      });

      elemek.push({
        name: "Kivitelezési díj",
        quantity: "1 klt",
      });

      const jsonData = {
        template_id: "Q75gTX20f1uildGFQqL8",
        data: {
          name: data.get("ugyfelnev"),
          address: data.get("ugyfelcim"),
          kwp:
            (parseInt(kw.substring(0, kw.length - 1)) * parseInt(napelemdb)) /
            1000,
          priceNoTax: arak["netto"],
          tax: arak["afa"],
          priceTax: arak["brutto"],
          items: elemek,
          ertekesitoNeve: isAdmin
            ? data.get("ertekesitonev")
            : sessionStorage.getItem("nev"),
          ertekesitoTelefonszam: isAdmin
            ? data.get("ertekesitotelefon")
            : sessionStorage.getItem("telefonszam"),
          date: today,
        },
        file: "",
        sheet: "",
        name: "",
        output_name:
          data.get("ugyfelnev") !== "" && data.get("ugyfelnev") != null
            ? data.get("ugyfelnev")
            : "arajanlat",
        output_format: ".pdf",
        output_quality: 100,
        single_file: true,
        page_break: true,
        merge_with: "",
      };

      $.ajax({
        url: "https://api.docugenerate.com/v1/document",
        type: "post",
        data: JSON.stringify(jsonData),
        headers: {
          accept: "application/json",
          Authorization: "945c5c49b7f73ff286550a920c167eba",
          "Content-Type": "application/json",
        },
        dataType: "json",
        success: function (data) {
          setSuccess(true);
          window.location.href = data.document_uri;
        },
        error: function (data) {
          setError(true);
        },
        complete: function (data) {
          setLoading(false);
        },
      });
    }
  };

  const loadData = () => {
    fetch("https://greenisland-api.onrender.com/blog")
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteBlog = (id) => {
    fetch("https://greenisland-api.onrender.com/blog/" + id, {
      method: "DELETE",
    }).then(() => loadData());
  };

  const getArak = (teljesar) => {
    function arformazo(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    if (teljesar === -1) {
      const napelemekar =
        napelem &&
        JSON.parse(napelem)["ar"] !== 0 &&
        JSON.parse(napelem)["ar"] !== ""
          ? JSON.parse(napelem)["ar"] * napelemdb
          : 0;
      const inverterekar =
        inverter &&
        JSON.parse(inverter)["ar"] !== 0 &&
        JSON.parse(inverter)["ar"] !== ""
          ? JSON.parse(inverter)["ar"] * inverterdb
          : 0;
      const akkumulatorar =
        akkumulator &&
        JSON.parse(akkumulator)["ar"] !== 0 &&
        JSON.parse(akkumulator)["ar"] !== ""
          ? JSON.parse(akkumulator)["ar"] * akkumulatordb
          : 0;
      const klimaar =
        klima && JSON.parse(klima)["ar"] !== 0 && JSON.parse(klima)["ar"] !== ""
          ? JSON.parse(klima)["ar"] * klimadb
          : 0;

      const inverter_kiegeszitokar =
        kiegeszito &&
        JSON.parse(kiegeszito)["ar"] !== 0 &&
        JSON.parse(kiegeszito)["ar"] !== ""
          ? JSON.parse(kiegeszito)["ar"] * kiegeszitokdb
          : 0;

      teljes =
        napelemekar +
        inverterekar +
        akkumulatorar +
        inverter_kiegeszitokar +
        klimaar +
        egyeb;
      teljes += napelemdb * munkadij;
      teljes += napelemdb * tartoszerkezet;
      teljesafaval = Math.round(teljes) * 1.27;
      afa = Math.round(teljesafaval) - Math.round(teljes);
    } else {
      teljesafaval = teljesar;
      teljes = Math.round(teljesafaval / 1.27);
      afa = teljesafaval - teljes;
    }

    setNettoAr(arformazo(Math.round(teljes)));
    setAfaAr(arformazo(Math.round(afa)));
    setTeljesAr(arformazo(Math.round(teljesafaval)));
    setFrissites(false);

    return {
      netto: arformazo(Math.round(teljes)),
      afa: arformazo(Math.round(afa)),
      brutto: arformazo(Math.round(teljesafaval)),
    };
  };
  if (isAdmin) {
    adminElements = (
      <>
        <Grid item xs={12} sm={6}>
          {" "}
          <TextField
            autoComplete="agent-name"
            name="ertekesitonev"
            required
            fullWidth
            id="ertekesitonev"
            label="Értékesítő név"
            autoFocus
            onChange={(e) => (ertekesitonev = e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="ertekesitotelefon"
            label="Értékesítő telefonszáma"
            name="ertekesitotelefon"
            autoComplete="agent-phone"
            onChange={(e) => (ertekesitotelefon = e.target.value)}
          />
        </Grid>
      </>
    );
  } else {
    adminElements = <></>;
  }
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Avatar sx={{ m: 1, bgcolor: "black" }}>
            <AttachMoneyRounded />
          </Avatar>
          <Typography component="h1" variant="h5">
            {!isBlog ? "Árajánlat készítése" : "Blog frissítése"}
          </Typography>
          {isAdmin ? (
            <Button
              className="btn-secondary-inverse"
              type="submit"
              id="frissit"
              fullWidth
              variant="contained"
              onClick={(e) => {
                if (currentEdit) {
                  setCurrentEdit(null);
                } else {
                  setIsBlog(!isBlog);
                }
              }}
              sx={{ mt: 3, mb: 2 }}>
              <ArrowBackRounded />
              {!currentEdit
                ? isBlog
                  ? "Árajánlat készítése"
                  : "Blog frissítése"
                : "Vissza a blogokhoz"}
            </Button>
          ) : (
            <></>
          )}
          {isAdmin && isBlog && !currentEdit ? (
            <Button
              className="btn-secondary-inverse"
              type="submit"
              id="frissit"
              fullWidth
              variant="contained"
              onClick={(e) => {
                setCurrentEdit({});
              }}
              sx={{ mb: 2 }}>
              <AddIcon />
              Új blog hozzáadása
            </Button>
          ) : (
            <></>
          )}

          {isBlog ? (
            <Box
              component="form"
              onSubmit={handleBlogFrissites}
              noValidate={true}
              sx={{ mt: 3 }}
              style={{ width: "100%" }}>
              {currentEdit ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      name="title"
                      required
                      fullWidth
                      id="title"
                      label="Blog címe"
                      defaultValue={currentEdit.title}
                      autoFocus
                      onChange={(e) => setBlogcim(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextareaAutosize
                      name="content"
                      required
                      style={{ width: "100%" }}
                      id="content"
                      placeholder="Blog tartalma"
                      defaultValue={currentEdit.content}
                      minRows={5}
                      autoFocus
                      onChange={(e) => setBlogtartalma(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}>
                      Fájl feltöltése
                      <VisuallyHiddenInput
                        type="file"
                        id="file"
                        name="file"
                        accept="image/jpg, image/png, image/jpeg, image/gif, video/mp4, video/mov, video/avi, video/wmv"
                      />
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}

              {!currentEdit ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <table>
                      <tbody>
                        {blogs.map((blog) => (
                          <tr key={blog.id}>
                            <td>{blog.title}</td>
                            <td style={{ textAlign: "right" }}>
                              <button
                                className="btn-secondary"
                                type="button"
                                onClick={() => setCurrentEdit(blog)}
                                style={{
                                  border: "1px solid black",
                                  padding: "12px",
                                  marginRight: "12px",
                                }}>
                                Szerkesztés
                              </button>{" "}
                              <button
                                className="btn-secondary-inverse"
                                style={{ padding: "12px" }}
                                type="button"
                                onClick={() => deleteBlog(blog.id)}>
                                Törlés
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}

              {currentEdit ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Button
                      className="btn-secondary-inverse"
                      style={{ width: "100%" }}
                      type="submit"
                      id="frissit"
                      variant="contained"
                      onClick={(e) => setFrissites(true)}
                      sx={{ mt: 3, mb: 2 }}>
                      <RefreshRounded />
                      Blog frissítése
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Box>
          ) : (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate={true}
              sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="customer-name"
                    name="ugyfelnev"
                    required
                    fullWidth
                    id="ugyfelnev"
                    label="Ügyfél név"
                    autoFocus
                    onChange={(e) => (ugyfelnev = e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="ugyfelcim"
                    label="Ügyfél cím"
                    name="ugyfelcim"
                    autoComplete="customer-address"
                    onChange={(e) => (ugyfelcim = e.target.value)}
                  />
                </Grid>
                {adminElements}
                <Grid item xs={10}>
                  <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Napelem
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={napelem}
                      label="Napelem"
                      onChange={handleChangeNapelem}>
                      {Object.entries(json["napelemek"]).map(([key, value]) => {
                        return (
                          <MenuItem
                            value={JSON.stringify({
                              ar: value["ar"],
                              nev: value["nev"],
                            })}
                            key={value["nev"] + value["ar"]}>
                            {value["nev"]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    required
                    fullWidth
                    id="napelemekszama"
                    label="Darab"
                    name="napelemekszama"
                    autoComplete="napelem-darab"
                    defaultValue={0}
                    onChange={(e) => (napelemdb = e.target.value)}
                  />
                </Grid>
                <Grid item xs={10}>
                  <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Inverter
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={inverter}
                      label="Inverter"
                      onChange={handleChangeInverter}>
                      {Object.entries(json["inverterek"]).map(
                        ([key, value]) => {
                          return (
                            <MenuItem
                              value={JSON.stringify({
                                ar: value["ar"],
                                nev: value["nev"],
                              })}
                              key={value["nev"] + value["ar"]}>
                              {value["nev"]}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    required
                    fullWidth
                    defaultValue={0}
                    id="inverterekszama"
                    label="Darab"
                    name="inverterekszama"
                    autoComplete="inverter-darab"
                    onChange={(e) => (inverterdb = e.target.value)}
                  />
                </Grid>
                <Grid item xs={10}>
                  <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Akkumulátor
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={akkumulator}
                      label="Napelem"
                      onChange={handleChangeAkkumulator}>
                      {Object.entries(json["akkumulatorok"]).map(
                        ([key, value]) => {
                          return (
                            <MenuItem
                              value={JSON.stringify({
                                ar: value["ar"],
                                nev: value["nev"],
                              })}
                              key={value["nev"] + value["ar"]}>
                              {value["nev"]}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    required
                    fullWidth
                    id="akkumulatorokszama"
                    label="Darab"
                    defaultValue={0}
                    name="akkumulatorokszama"
                    autoComplete="akkumulator-darab"
                    onChange={(e) => (akkumulatordb = e.target.value)}
                  />
                </Grid>
                <Grid item xs={10}>
                  <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Inverter kiegészítő
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={kiegeszito}
                      label="Inverter kiegészítő"
                      onChange={handleChangeKiegeszito}>
                      {Object.entries(json["inverter_kiegeszitok"]).map(
                        ([key, value]) => {
                          return (
                            <MenuItem
                              value={JSON.stringify({
                                ar: value["ar"],
                                nev: value["nev"],
                              })}
                              key={value["nev"] + value["ar"]}>
                              {value["nev"]}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    required
                    fullWidth
                    id="kiegeszitokszama"
                    label="Darab"
                    name="kiegeszitokszama"
                    defaultValue={0}
                    autoComplete="kiegeszitok-darab"
                    onChange={(e) => (kiegeszitokdb = e.target.value)}
                  />
                </Grid>
                <Grid item xs={10}>
                  <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                    <InputLabel id="demo-simple-select-label">Klíma</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={klima}
                      label="Klíma"
                      onChange={handleChangeKlima}>
                      {Object.entries(json["klimak"]).map(([key, value]) => {
                        return (
                          <MenuItem
                            value={JSON.stringify({
                              ar: value["ar"],
                              nev: value["nev"],
                            })}
                            key={value["nev"] + value["ar"]}>
                            {value["nev"]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    required
                    fullWidth
                    id="klimakszama"
                    label="Darab"
                    name="klimakszama"
                    autoComplete="napelem-darab"
                    defaultValue={0}
                    onChange={(e) => (klimadb = e.target.value)}
                  />
                </Grid>
              </Grid>
              <TableContainer component={Paper} style={{ marginTop: "48px" }}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow
                      key={"nettoar"}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <TableCell component="th" scope="row">
                        Új napelemes rendszer megvalósítása kW méretben (nettó
                        Ft)
                      </TableCell>
                      <TableCell align="right">{nettoAr} Ft</TableCell>
                    </TableRow>
                    <TableRow
                      key="afa"
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <TableCell component="th" scope="row">
                        ÁFA (27%)
                      </TableCell>
                      <TableCell align="right">{afaAr} Ft</TableCell>
                    </TableRow>
                    <TableRow
                      key="teljesar"
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <TableCell component="th" scope="row">
                        Végösszeg (bruttó Ft)
                      </TableCell>
                      <TableCell align="right">
                        <strong
                          contentEditable={isAdmin}
                          suppressContentEditableWarning={true}
                          id="teljesAr"
                          onBlur={handleBlur}>
                          {teljesAr}
                        </strong>
                        <span> Ft</span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Button
                    className="btn-secondary-inverse"
                    type="submit"
                    id="frissit"
                    fullWidth
                    variant="contained"
                    onClick={(e) => setFrissites(true)}
                    sx={{ mt: 3, mb: 2 }}>
                    <RefreshRounded />
                    Frissítés
                  </Button>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Button
                    className="btn-secondary-inverse"
                    type="submit"
                    id="bekuld"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}>
                    {loadingButton}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ArajanlatozoModel;
