import "../solar/solar-model.scss";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import json from "./0ae127cd7614241e3a8527b0fa1a1900.json";

const BejelentkezesModel = () => {
  if (sessionStorage.getItem("felhasznalonev")) {
    window.location.href = "f9a3c2dd20d43eda5d3e522ba2b79dfc";
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    if (data.get("felhasznalonev") in json) {
      if (
        btoa(data.get("jelszo")) ===
        json[data.get("felhasznalonev")]["password"]
      ) {
        sessionStorage.setItem("nev", json[data.get("felhasznalonev")]["name"]);
        sessionStorage.setItem("felhasznalonev", data.get("felhasznalonev"));
        sessionStorage.setItem(
          "telefonszam",
          json[data.get("felhasznalonev")]["telefonszam"]
        );
        window.location.href = "f9a3c2dd20d43eda5d3e522ba2b79dfc";
      } else {
        document.getElementById("incorrect-pass").innerHTML =
          "<strong>Helytelen Jelszó!</strong>";
      }
    }
  };

  const defaultTheme = createTheme();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Avatar sx={{ m: 1, bgcolor: "black" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Bejelentkezés
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="felhasznalonev"
              label="Felhasználónév"
              name="felhasznalonev"
              autoComplete="felhasznalonev"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="jelszo"
              label="Jelszó"
              type="password"
              id="jelszo"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              className="btn-secondary-inverse"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
              Bejelentkezés
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default BejelentkezesModel;
