import EmailJS from "@emailjs/browser";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import logo from "../../assets/solar/floatinglogo.svg";
import "./FloatingChatButton.scss";

const FloatingChatButton = forwardRef((props, ref) => {
  EmailJS.init({
    publicKey: "M8bnFBJgRsoMNF3Hf",
    // Do not allow headless browsers
    blockHeadless: true,
    limitRate: {
      // Set the limit rate for the application
      id: "app",
      // Allow 1 request per 10s
      throttle: 10000,
    },
  });
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subscribe, setSubscribe] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);

  useImperativeHandle(ref, () => ({
    openChat: () => setIsChatOpen(true),
  }));

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubscribeChange = (e) => {
    setSubscribe(e.target.checked);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let feliratkozott = subscribe
      ? "Feliratkozott a hírlevélre."
      : "Nem iratkozott fel a hírlevélre.";
    EmailJS.send("service_x9pofce", "template_261u3o4", {
      name,
      email,
      phone,
      feliratkozott,
    })
      .then(() => {
        setName("");
        setEmail("");
        setPhone("");
        setSubscribe(false);
        setSuccess(true);
        setError(false);
      })
      .catch(() => {
        setSuccess(false);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let loadingButton;

  if (isLoading) {
    loadingButton = (
      <div className="load-wrapp">
        <div className="load-2">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    );
  } else {
    if (isSuccess) {
      loadingButton = <span>Sikeres beküldés!</span>;
    } else if (isError) {
      loadingButton = <span>Hiba történt!</span>;
    } else {
      loadingButton = <span>Visszahívás</span>;
    }
  }

  let color;

  if (isLoading) {
    color = "black";
  } else {
    if (isSuccess) {
      color = "#008000";
    } else if (isError) {
      color = "#C70039";
    } else {
      color = "black";
    }
  }

  return (
    <div className="floating-chat-button">
      <img src={logo} alt="Lebegő Chat logo" className="floating-chat-logo" />
      <div className={`chat-box ${isChatOpen ? "open" : "close"}`}>
        <h3 style={{ textAlign: "center", marginBottom: "24px" }}>
          Visszahívás kérése
        </h3>
        <p style={{ textAlign: "justify", marginBottom: "24px" }}>
          Töltse ki az alábbi mezőket adataival, és munkanapokon 24 órán belül
          visszahívjuk!
        </p>
        <form onSubmit={handleSubmit} id="emailForm">
          <input
            type="text"
            placeholder="Teljes Név"
            value={name}
            required
            onChange={handleNameChange}
          />
          <input
            type="email"
            placeholder="E-mail cím"
            value={email}
            required
            onChange={handleEmailChange}
          />
          <input
            type="tel"
            placeholder="Telefonszám"
            value={phone}
            required
            onChange={handlePhoneChange}
          />
          <div style={{ marginTop: "18px", marginBottom: "24px" }}>
            {" "}
            <input
              style={{
                height: "18px",
                float: "left",
                width: "auto",
                accentColor: "black",
              }}
              type="checkbox"
              name="subscribe"
              id="subscribe"
              className="hover-cursor"
              placeholder="Feliratkozás"
              value={subscribe}
              onChange={handleSubscribeChange}
            />
            <label
              htmlFor="subscribe"
              style={{ paddingLeft: "12px" }}
              className="hover-cursor">
              {" "}
              Feliratkozom a hírlevelekre.
            </label>
          </div>
          <button
            type="submit"
            style={{ backgroundColor: color }}
            disabled={isLoading}>
            {loadingButton}
          </button>
        </form>
        <button className="close-button" onClick={toggleChat}>
          &times;
        </button>
      </div>
      <div className="circle" onClick={toggleChat}></div>
    </div>
  );
});

export default FloatingChatButton;
