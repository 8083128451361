import {
  AttachMoneyRounded,
  FlashOnRounded,
  WatchLaterRounded,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionSummary } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { MenuContext } from "../../contexts/menu-context";
import FloatingChatButton from "../floatingchat/FloatingChat";
import Modal from "../Modal/Modal";
import "./solar-model.scss";

const Solar = (props) => {
  return <>{<SolarItem />}</>;
};

const SolarItem = ({ solar }) => {
  const { isMenuShown } = useContext(MenuContext);
  const divRef = useRef();
  const videoRef = useRef();
  const heroRef = useRef();
  const video5Ref = useRef();
  const hero5Ref = useRef();
  const video6Ref = useRef();
  const hero6Ref = useRef();
  const chatButton = useRef();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [blogs, setBlogs] = useState([]);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            videoRef.current.play();
          } else {
            videoRef.current.pause();
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    videoRef.current.addEventListener("loadeddata", () => {
      if (videoRef && videoRef.current) {
        videoRef.current.play();
      }
    });

    const currentVideoRef = videoRef.current; // Create a variable to store the value of videoRef.current
    const currentDivRef = heroRef.current;
    observer.observe(currentDivRef);

    return () => {
      observer.unobserve(currentDivRef);
      // Cleanup event listener using the variable
      currentVideoRef.removeEventListener("loadeddata", () => {
        currentVideoRef.play();
      });
    };
  }, [videoRef, heroRef]);

  useEffect(() => {
    fetch("https://greenisland-api.onrender.com/blog")
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            video5Ref.current.play();
          } else {
            video5Ref.current.pause();
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    video5Ref.current.addEventListener("loadeddata", () => {
      if (video5Ref && video5Ref.current) {
        video5Ref.current.play();
      }
    });

    const currentVideoRef = video5Ref.current; // Create a variable to store the value of videoRef.current
    const currentDivRef = hero5Ref.current;
    observer.observe(currentDivRef);

    return () => {
      observer.unobserve(currentDivRef);
      // Cleanup event listener using the variable
      currentVideoRef.removeEventListener("loadeddata", () => {
        currentVideoRef.play();
      });
    };
  }, [video5Ref, hero5Ref]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            video6Ref.current.play();
          } else {
            video6Ref.current.pause();
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    video6Ref.current.addEventListener("loadeddata", () => {
      if (video6Ref && video6Ref.current) {
        video6Ref.current.play();
      }
    });

    const currentVideoRef = video6Ref.current; // Create a variable to store the value of videoRef.current
    const currentDivRef = hero6Ref.current;
    observer.observe(currentDivRef);

    return () => {
      observer.unobserve(currentDivRef);
      // Cleanup event listener using the variable
      currentVideoRef.removeEventListener("loadeddata", () => {
        currentVideoRef.play();
      });
    };
  }, [video6Ref, hero6Ref]);

  useEffect(() => {
    if (isMenuShown) {
      divRef.current.classList.add("darken");
    } else {
      divRef.current.classList.remove("darken");
    }
  }, [isMenuShown]);

  return (
    <>
      <Modal show={showModal} onClose={closeModal} videoId="nExFNbJ84gY" />
      <div className="solar" ref={divRef}>
        <div className="solar__hero fade-bottom" ref={heroRef} id="top">
          <video
            webkit-playsinline="true"
            playsInline
            ref={videoRef}
            className="solar__hero__video"
            loop
            muted
            preload="auto">
            <source
              src={
                location.pathname === "/" || location.pathname === "/lakossagi"
                  ? require(`../../assets/solar/1_magan.mp4`)
                  : require(`../../assets/solar/2_vallalati.mp4`)
              }
              type="video/mp4"
            />
          </video>
          <div
            className="solar__hero__info"
            style={{ color: "#fff", zIndex: 1 }}>
            <motion.h1
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}>
              {location.pathname === "/" || location.pathname === "/lakossagi"
                ? "Napelemek az otthonodra"
                : "Vállalati napelemes rendszerek"}
            </motion.h1>
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75 }}>
              A jövő energiája
            </motion.p>
          </div>

          <div className="solar__hero__specs">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0 }}
              className="solar__hero__specs__item">
              <h2>
                {" "}
                <AttachMoneyRounded
                  sx={{ fontSize: 45 }}
                  style={{ verticalAlign: "text-top" }}
                />
              </h2>
              <p>A legjobb csomagajánlatok</p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.33 }}
              className="solar__hero__specs__item">
              <h2>
                {" "}
                <FlashOnRounded
                  sx={{ fontSize: 45 }}
                  style={{ verticalAlign: "text-top" }}
                />
              </h2>
              <p>Energia függetlenség</p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.67 }}
              className="solar__hero__specs__item">
              <h2>
                {" "}
                <WatchLaterRounded
                  sx={{ fontSize: 45 }}
                  style={{ verticalAlign: "text-top" }}
                />
              </h2>
              <p>0-24 távfelügyelet</p>
            </motion.div>

            <div style={{ flexBasis: "100%", height: 0 }}></div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
              className="solar__hero__specs__item">
              <button
                className="btn-secondary-inverse"
                style={{ maxWidth: "300px" }}
                onClick={() => chatButton.current.openChat()}>
                Jelentkezem most
              </button>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
              className="solar__hero__specs__item">
              <button
                className="btn-secondary-inverse"
                style={{ height: "100%" }}
                onClick={() => chatButton.current.openChat()}>
                Visszahívást kérek
              </button>
            </motion.div>
          </div>
        </div>

        <div className="solar__about" id="napelemes_rendszerek">
          <div
            className="solar__about__media fade-bottom-left"
            style={{ order: 1 }}>
            <img
              src={
                location.pathname === "/" || location.pathname === "/lakossagi"
                  ? require(`../../assets/solar/oktatoshatter.jpg`)
                  : require(`../../assets/solar/oktatoshatter_vallalati.jpg`)
              }
              alt="about"
            />
          </div>

          <motion.div
            className="solar__about__info"
            initial={{
              y: "20%",
              opacity: 0,
            }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}>
            <div className="solar__about__info__text">
              <h3>
                {location.pathname === "/" || location.pathname === "/lakossagi"
                  ? "Korszerű"
                  : "A Green Island Invest Zrt. a jövő energiája!"}
              </h3>
              <h2>
                {location.pathname === "/" || location.pathname === "/lakossagi"
                  ? "Napelemes rendszerek"
                  : "Energetikai függetlenség és energiahatékonyság"}
              </h2>
              <p>
                {location.pathname === "/" || location.pathname === "/lakossagi"
                  ? "A napelemes lakossági telepítés egyre népszerűbbé vált, hiszen számos előnyt kínál. Az egyik legfontosabb kérdés a megtérülési idő, amely általában 3-6 év között mozog a beruházás nagyságától és az energiafogyasztástól függően. Ez azt jelenti, hogy a befektetés viszonylag gyorsan megtérül, miközben hosszú távon jelentős megtakarítást eredményez a háztartások számára. De miért éri meg igazán? A napelemes rendszerek nemcsak anyagi előnyökkel járnak, hanem csökkentik az energiafüggőséget és hozzájárulnak a környezetvédelemhez is. Az általános bemutatás érdekében elmondható, hogy a telepítés folyamata egyszerűbb és gyorsabb, mint valaha, és az állami támogatások is egyre vonzóbbá teszik ezt a zöld beruházást."
                  : "Miért válassza a Green Island Invest Zrt.-t? Ennek több oka is van. Először is, cégünk napelemes rendszerek telepítésére és fenntartására szakosodott, amelyek nemcsak környezetbarátak, hanem hosszú távon jelentős költségmegtakarítást is eredményeznek. Másodszor, a Green Island Invest Zrt. garantálja a teljes folyamat zökkenőmentes lebonyolítását, a tervezéstől kezdve egészen a kivitelezésig és az üzembe helyezésig. Az energiafüggetlenség elérése nemcsak környezetvédelmi szempontból előnyös, hanem gazdaságilag is kifizetődő. A napelemes rendszerek használatával az ingatlanok értéke növekszik, és csökkennek az energiafogyasztási költségek. A napenergia tiszta és megújuló forrás, amely jelentős mértékben csökkentheti az Ön áramfogyasztását és így a rezsiköltségeit is. A Green Island Invest Zrt. elkötelezett amellett, hogy ügyfelei számára a legjobb megoldásokat nyújtsa. Az általunk kínált szolgáltatások között szerepel a napelemes rendszerek telepítése, karbantartása és modernizálása, valamint az energiahatékonyság növelése érdekében cégünk komplex energetikai auditot biztosít, amely során feltárjuk az optimalizálási lehetőségeket. Összefoglalva, a Green Island Invest Zrt. nemcsak a jövő energiáját hozza el ügyfelei számára, hanem biztosítja az energiafüggetlenséget és az energiahatékonyságot is. Profi kivitelezés és ügyintézés révén ügyfeleink biztosak lehetnek abban, hogy a legjobb megoldást kapják. Cégünk tapasztalt szakemberei minden projektet személyre szabottan kezelnek, így garantálva az optimális teljesítményt és maximális megtakarítást."}
              </p>
            </div>
            {/* PIXEL ID: 564164925578279 */}
            <motion.div
              className="solar__about__info__btn"
              style={{
                gap: "1rem",
                alignItems: "normal",
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                paddingTop: "1.5rem",
              }}
              initial={{
                y: "100%",
                opacity: 0,
              }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.33 }}>
              <button
                className="btn-secondary-inverse"
                onClick={() => chatButton.current.openChat()}>
                Visszahívást kérek
              </button>
            </motion.div>
          </motion.div>
        </div>

        {/* <div className="solar__section">
          <div
            className="solar__section__cover"
            style={{
              backgroundImage: `url(${require("../../assets/solar/oktatoshatter.jpg")})`,
            }}></div>
          <div className="solar__section__specs">
            <motion.div
              className="solar__section__specs__div"
              style={{ gap: "2rem", alignItems: "normal" }}
              initial={{
                y: "100%",
                opacity: 0,
              }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.33 }}>
              <div className="solar__section__specs__div__main">
                <h2>Akksis videó</h2>
                <div className="button_div">
                  <button
                    style={{ padding: "0.6rem 2.125rem" }}
                    className="btn-secondary-inverse"
                    onClick={() => chatButton.current.openChat()}>
                    Visszahívást kérek
                  </button>
                  <button
                    className="btn-secondary-inverse"
                    onClick={openModal}
                    style={{ padding: "0.6rem 2.125rem" }}>
                    Oktatóvideó
                  </button>
                </div>
              </div>

              <div className="solar__section__specs__div__secondary">
                <p>
                  Manage your solar system from anywhere in the world with 24/7
                  mobile monitoring. Watch your energy in real time or set your
                  preferences to optimize for energy independence. <br></br>{" "}
                  AKKSI TELEPÍTÉS MENETE!!!
                </p>
              </div>
            </motion.div>
          </div>
        </div> */}

        {/* <div className="solar__section">
          <div ref={hero5Ref}>
            <video
              style={{ width: "100%" }}
              ref={video5Ref}
              loop
              muted
              preload="auto">
              <source
                src={require(`../../assets/solar/akksitelepites.mp4`)}
                type="video/mp4"
              />
            </video>
          </div>
        </div> */}
        <div className="solar__about" id="energiatarolo">
          <div className="solar__about__media">
            <div ref={hero5Ref} className="fade-bottom-top-right">
              <video
                webkit-playsinline="true"
                playsInline
                style={{ width: "100%" }}
                ref={video5Ref}
                loop
                muted
                preload="auto">
                <source
                  src={
                    location.pathname === "/" ||
                    location.pathname === "/lakossagi"
                      ? require(`../../assets/solar/akksitelepites.mp4`)
                      : require(`../../assets/solar/akksitelepites_2_new.mp4`)
                  }
                  type="video/mp4"
                />
              </video>
            </div>
          </div>

          <motion.div
            className="solar__about__info"
            initial={{
              y: "20%",
              opacity: 0,
            }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}>
            <div className="solar__about__info__text">
              <h3>
                {location.pathname === "/" || location.pathname === "/lakossagi"
                  ? "A szolgáltatói függetlenség előnyei"
                  : "Megtérülési idő"}
              </h3>
              <h2>
                {location.pathname === "/" || location.pathname === "/lakossagi"
                  ? "Miért hatékonyabb a napelemes rendszer akkumulátorral?"
                  : "Hogyan érhet el gyors megtérülést vállalata napelemes rendszerrel?"}
              </h2>
              <p>
                {location.pathname === "/" || location.pathname === "/lakossagi"
                  ? `Az akkumulátorok szerepe a háztartásokban egyre jelentősebbé
                válik. Napelemes rendszerek akkumulátorral kiegészítve nemcsak
                hatékonyabb energiatárolást biztosítanak, hanem stabilabbá is
                teszik , kiegyenlítik az ingadozásokat, biztosítva a folyamatos
                energiaellátást akár áramszünet esetén is. Az áramszolgáltatótól való függetlenedés, vagyis amikor a háztartás önállóan
                képes energiát termelni és tárolni, számos előnnyel jár. Miért
                hatékony az energiatárolás? Az akkumulátorok lehetővé teszik az
                energia felhasználását akkor is, amikor a nap nem süt, így
                folyamatos áramellátást biztosítanak. Összességében, miért
                stabilabb a rendszer akkumulátorral? Mert jobban képes kezelni
                az energiaigény ingadozásait és növeli az önellátás lehetőségét is.`
                  : `Az energiaárak emelkedése és az energiahatékonyság iránti igény egyre
több vállalatot ösztönöz arra, hogy napelemes rendszert telepítsen. De
miért éri meg cégként napelemes rendszer beruházásba kezdeni? A válasz
egyszerű: a gyors megtérülés.

Az energiaárak folyamatos növekedése komoly kihívást jelent a
vállalatok számára. Egy napelemes rendszer telepítésével nemcsak az
energiafüggetlenséget biztosíthatja, hanem jelentős
költségmegtakarítást is elérhet. Az energiahatékonyság növelésével
csökkentheti az üzemeltetési költségeket, ami közvetlenül hozzájárulhat
a profit növeléséhez.


A megtérülési idő szempontjából a napelemes rendszerek egyik
legnagyobb előnye, hogy az energiafogyasztástól és a rendszer
méretétől függően már néhány év alatt megtérülhet a beruházás. Így a
cég gyorsan visszanyeri a befektetett összeget, majd éveken át
élvezheti a szinte ingyenes energiát.


Fontos megjegyezni, hogy a napelemes rendszerek nemcsak pénzügyi
előnyöket kínálnak, egy jól karbantartott napelemes rendszer akár
25-30 évig is működőképes maradhat, így a vállalkozás hosszú távon is
élvezheti az alacsonyabb energiaárakat.A környezetbarát energiaforrás
használatával hozzájárulhatunk a fenntartható jövőhöz, és javíthatjuk
cégünk megítélését és társadalmi felelősségvállalását.


Összefoglalva, a napelemes rendszer telepítése stratégiai döntés,
amely hosszú távon jelentős előnyökkel jár. Az energiaárak
csökkentése, az energiahatékonyság növelése és a gyors megtérülés mind
olyan tényezők, amelyek miatt érdemes megfontolni ezt a beruházást. Ne
habozzon, lépjen a jövő energiatakarékos útjára!
`}
              </p>
            </div>

            <div
              className="solar__about__info__btn"
              style={{
                gap: "1rem",
                alignItems: "normal",
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                paddingTop: "1.5rem",
              }}>
              <button
                style={{ padding: "0.6rem 2.125rem" }}
                className="btn-secondary-inverse"
                onClick={() => chatButton.current.openChat()}>
                Visszahívást kérek
              </button>
              {location.pathname === "/" ||
              location.pathname === "/lakossagi" ? (
                <button
                  className="btn-secondary-inverse"
                  onClick={openModal}
                  style={{ padding: "0.6rem 2.125rem" }}>
                  Oktatóvideó
                </button>
              ) : (
                ""
              )}
            </div>
          </motion.div>
        </div>

        <div
          id="szolgaltatasaink"
          className="solar__section"
          style={{
            flexDirection: "row",
            color: "#171a20",
            justifyContent: "center",
          }}>
          <motion.div
            className="cardsContainer"
            initial={{
              y: "20%",
              opacity: 0,
            }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}>
            <div>
              <div className="container">
                <motion.div
                  className="child"
                  initial={{
                    y: "20%",
                    opacity: 0,
                  }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0 }}>
                  <div className="card">
                    <div className="img-container">
                      <img
                        src={
                          location.pathname === "/" ||
                          location.pathname === "/lakossagi"
                            ? require(`../../assets/solar/napelemes_rendszer.jpg`)
                            : require(`../../assets/solar/napelemes_rendszer_2.jpg`)
                        }
                        alt="Napelemes rendszer"
                        className="card-img"
                      />
                    </div>
                    <h3 className="title">Napelemes rendszer</h3>
                    <p>
                      {location.pathname === "/" ||
                      location.pathname === "/lakossagi"
                        ? `Napelemes rendszer telepítésünk teljeskörű megoldást
                      kínál lakossági és vállalati ügyfeleknek egyaránt.
                      Tapasztalt csapatunk tervezéstől kezdve a telepítésig
                      gondoskodik arról, hogy az igényeidnek és az ingatlan
                      lehetőségeinek megfelelő napelemes rendszert hozzunk
                      létre. Az általunk kínált rendszer nemcsak csökkenti az
                      energiaszámlát, hanem hozzájárul a fenntarthatósághoz is.
                      Legyen szó otthoni vagy üzleti környezetről, a napelemes
                      rendszerünk segít csökkenteni a környezeti lábnyomát
                      és pénzt megtakarítani hosszú távon.`
                        : `A Green Island Invest Zrt.
szakértelme révén a vállalati napelemes rendszerek telepítése most
minden eddiginél egyszerűbb és hatékonyabb. De vajon mennyi idő alatt
térül meg egy ilyen beruházás?

Az első lépés a napelemes rendszer telepítésében a megfelelő tervezés.
A Green Island Invest Zrt. profi munkatársai alapos helyszíni felmérést
végeznek, hogy a lehető legjobb megoldást kínálják ügyfeleik számára.
A vállalati rendszer telepítése során figyelembe veszik az épület
adottságait és az energiaigényeket, így biztosítva, hogy a rendszer
optimálisan működjön. Egy jól megtervezett napelemes
rendszer esetén a megtérülési idő általában 1,5-3  év között mozog.`}
                    </p>
                  </div>
                </motion.div>
                <motion.div
                  className="child"
                  initial={{
                    y: "20%",
                    opacity: 0,
                  }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.33 }}>
                  <div className="card">
                    <div className="img-container">
                      <img
                        src={require(`../../assets/solar/autotolto.jpg`)}
                        alt="Autótöltő"
                        className="card-img"
                      />
                    </div>
                    <h3 className="title">Elektromos autó töltő</h3>
                    <p>
                      Az elektromos autó töltő telepítés magába foglalja a
                      teljes folyamatot a tervezéstől a telepítésig. Tapasztalt
                      szakembereink gondoskodnak arról, hogy az otthonodban vagy
                      vállalkozásodnak a legmegfelelőbb töltőrendszert
                      installálják, figyelembe véve az elektromos autó típusát
                      és az egyedi igényeid. Biztosítjuk a megfelelő elektromos
                      csatlakozást és az összes szükséges engedélyeztetést.
                      Szolgáltatásunk gyors, megbízható és kényelmes, hogy Neked
                      csak az autózás örömére kelljen koncentrálnia.
                    </p>
                  </div>
                </motion.div>
                <motion.div
                  className="child"
                  initial={{
                    y: "20%",
                    opacity: 0,
                  }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.67 }}>
                  <div className="card">
                    <div className="img-container">
                      <img
                        src={require(`../../assets/solar/Carport.jpg`)}
                        alt="Carport"
                        className="card-img"
                      />
                    </div>
                    <h3 className="title">Carport</h3>
                    <p>
                      Carport telepítés során teljeskörű megoldást nyújtunk az
                      autóvédelem és a megújuló energia hasznosítása terén.
                      Tapasztalt csapatunk tervezéstől kezdve a telepítésig
                      gondoskodik arról, hogy az igényeidnek megfelelő carport
                      rendszert építse ki, napelemekkel ellátva. Az így
                      létrehozott rendszer nemcsak az autókat védve biztosít
                      árnyékot, hanem a napelemek által termelt energiával is
                      hozzájárul a fenntartható energiatermeléshez, és akár az
                      autó töltését is támogathatja.
                    </p>
                  </div>
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>

        <div
          className="solar__section"
          style={{ position: "relative" }}
          id="tavfelugyelet">
          <div
            ref={hero6Ref}
            className="fade-bottom-top"
            style={{ position: "relative" }}>
            <video
              webkit-playsinline="true"
              playsInline
              style={{ width: "100%" }}
              ref={video6Ref}
              loop
              muted
              preload="auto">
              <source
                src={require(`../../assets/solar/monitoring.mp4`)}
                type="video/mp4"
              />
            </video>
          </div>

          <div className="solar__section__specs">
            <motion.div
              className="solar__section__specs__div"
              initial={{
                y: "100%",
                opacity: 0,
              }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}>
              <div className="solar__section__specs__div__main">
                <p>Élvezzen</p>
                <h2>0-24 Távfelügyeletet</h2>
                <button
                  className="btn-secondary-inverse"
                  onClick={() => chatButton.current.openChat()}>
                  Visszahívást kérek
                </button>
              </div>

              <div className="solar__section__specs__div__secondary">
                <p style={{ textAlign: "justify" }}>
                  A távfelügyelet további kényelmet biztosít az ügyfelek
                  számára, hiszen a rendszer állapotáról szóló információkat
                  bármikor elérhetik online felületen keresztül. Ezenfelül a
                  szolgáltató valós idejű adatokat kap, amelyek alapján gyorsan
                  beavatkozhat, ha szükséges.A Green Island Invest Zrt. számára
                  elengedhetetlen, hogy a napelemes rendszer tulajdonosai
                  biztonságban érezzék magukat, és meg legyenek elégedve a
                  szolgáltatással.
                </p>
              </div>
            </motion.div>
          </div>
          <div id="blogok">
            {blogs.map((blog, ind) => {
              return (
                <div
                  key={blog.id}
                  className="solar__section__specs blog-parent"
                  style={{ marginTop: ind === 0 ? "70px" : "0px" }}>
                  <div
                    className="solar__section__specs__div blog-second-parent"
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 * ind + 1 }}
                    style={{ width: "100%" }}>
                    <div
                      className="solar__section__specs__div__main"
                      style={{ width: "100%" }}>
                      <Accordion
                        style={{
                          backgroundColor: "transparent",
                          color: "white",
                          border: "2px solid white",
                          borderRadius: "5px",
                        }}>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon style={{ color: "white" }} />
                          }
                          aria-controls="panel1-content"
                          id="panel1-header">
                          <h2>{blog.title}</h2>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            style={{
                              width: "100%",
                              textAlign: "right",
                              paddingBottom: "16px",
                            }}>
                            <a className="blog-link" href={"/blog/" + blog.id}>
                              Tovább a blogra
                            </a>
                          </div>

                          <pre className="blog-text-main-page">
                            {blog.content}
                          </pre>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/*
        <div className="solar__about">
          <div className="solar__about__media" style={{ order: 1 }}>
            <img
              src={require(`../../assets/solar/${
                isTablet ? "panels-about-mobile.jpg" : "panels-about-desktop.jpg"
              }`)}
              alt="about"
            />
          </div>

          <motion.div
            className="solar__about__info"
            initial={{
              y: "20%",
              opacity: 0,
            }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}>
            <div className="solar__about__info__text"></div>

            <div className="solar__about__info__btn">
              <button className="btn-secondary-inverse">ORDER NOW2</button>
            </div>
          </motion.div>
        </div>

        <div className="solar__section">
          <div
            className="solar__section__cover"
            style={{
              backgroundImage: `url(${require("../../assets/solar/battery-desktop.jpg")})`,
            }}></div>

          <SectionSpecs
            about="Powerwall"
            title="Home Battery Backup"
            description="Powerwall is bundled with every Tesla solar purchase, allowing you to store your solar energy for use anytime—at night or during an outage."
          />
        </div>

        <div className="solar__about">
          <div className="solar__about__media" style={{ order: 1 }}>
            <img
              src={require(`../../assets/solar/${
                isTablet ? "efficiency-mobile.jpg" : "efficiency-desktop.jpg"
              }`)}
              alt="about"
            />
          </div>

          <motion.div
            className="solar__about__info"
            initial={{
              y: "20%",
              opacity: 0,
            }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}>
            <div className="solar__about__info__text">
              <h3>Efficiency</h3>
              <h2>Maximum Solar Production</h2>
              <p>
                Generate the most energy possible, even on roofs with
                complicated angles and intermittent sunlight. Combined with
                Tesla Solar Inverter, your fully integrated system is safe,
                reliable and outage ready. With built-in connectivity, Tesla
                Solar Inverter will continue to improve with each over-the-air
                software update.
              </p>
            </div>

            <div className="solar__about__info__btn">
              <button className="btn-secondary-inverse">ORDER NOW 2</button>
            </div>
          </motion.div>
        </div> */}
        {/* 
        <div className="solar__about">
          <div className="solar__about__media" style={{ order: 1 }}>
            <img
              src={require(`../../assets/solar/${
                isTablet ? "electricity-mobile.jpg" : "electricity-desktop.jpg"
              }`)}
              alt="about"
            />
          </div>

          <motion.div
            className="solar__about__info"
            initial={{
              y: "20%",
              opacity: 0,
            }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}>
            <div className="solar__about__info__text">
              <h3>Value</h3>
              <h2>Pay Less for Electricity</h2>
              <p>
                Solar panel is the only roof that can help pay for itself with
                the energy you produce. Power your home at the lowest price per
                watt of any national provider and take control of your monthly
                electricity bill.
              </p>
            </div>

            <div className="solar__about__info__btn">
              <button className="btn-secondary-inverse">ORDER NOW</button>
            </div>
          </motion.div>
        </div> */}

        {/* <div className="solar__specs">
          <div className="solar__specs__container">
            <div className="solar__specs__container__img">
              <img
                src={require(`../../assets/solar/panels-specs.jpg`)}
                alt="solar item"
              />
            </div>

            <motion.div
              className="solar__specs__container__specs"
              initial={{
                y: "50%",
                opacity: 0,
              }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}>
              <div className="solar__specs__container__specs__title">
                <h2>
                  Solar panel <span>Specs</span>
                </h2>
              </div>

              <div className="solar__specs__container__specs__details">
                <SpecsDetailsItem title="Wattage" specs={"400 W"} />
                <SpecsDetailsItem
                  title="Operating Temperature"
                  specs={"-40°F up to +185°F"}
                />
                <SpecsDetailsItem
                  title="Dimensions"
                  specs={'74.4" x 41.2" x 1.57"'}
                />
                <SpecsDetailsItem
                  title="Design"
                  specs={"Black sollar cells and aluminium backsheet"}
                />
                <SpecsDetailsItem
                  title="Warranty"
                  specs={"25-year performance guarantee"}
                />
                <SpecsDetailsItem
                  title={"Certifications"}
                  specs={"IEC / UL 61730, CEC Listed"}
                />
                <SpecsDetailsItem
                  title="Inverter Power"
                  specs={"3.8kW / 7.6kW 97.5% efficiency"}
                />
                <SpecsDetailsItem
                  title="Fire Rating"
                  specs={"Class A (highest rating)"}
                />
              </div>
            </motion.div>
          </div>
        </div> */}

        <div className="solar__footer" id="kapcsolat">
          <div className="solar__footer__container">
            <h2>Green Island Invest Zrt.</h2>
            <div style={{ fontSize: "0.8rem" }}>
              <p>2074 Perbál Ipari Park 17</p>
              <p>info@greenislandinvest.hu</p>
              <p>+36 1 300 9240</p>
              <p>+36 70 / 315-7070</p>
              <p>+36 70 / 309-7070</p>
            </div>
            <div className="solar__footer__container__btns">
              <button
                className="btn-secondary-inverse"
                onClick={() => chatButton.current.openChat()}>
                Visszahívást kérek
              </button>
            </div>
          </div>
        </div>
      </div>
      <FloatingChatButton ref={chatButton} />
    </>
  );
};

// const SpecsDetailsItem = ({ title, specs }) => {
//   return (
//     <div className="solar__specs__container__specs__details__item">
//       <p className="heading">{title}</p>
//       <p>{specs}</p>
//     </div>
//   );
// };

// const SpecsItem = ({ title, label, delay }) => {
//   return (
//     <motion.div
//       initial={{ opacity: 0 }}
//       whileInView={{ opacity: 1 }}
//       transition={{ duration: 1, delay: delay }}
//       className="solar__hero__specs__item">
//       <h2>{title}</h2>
//       <p>{label}</p>
//     </motion.div>
//   );
// };

export default Solar;
